import React from "react";
import Slider from "react-slick";
import { Scrollbars } from "react-custom-scrollbars-2";
import Image from "next/image";
import useIsMobile from "../../hooks/useIsMobile";
import { configuration } from "../../helpers/config";
import { Constants } from "../../helpers/constants";
import "slick-carousel/slick/slick.css";

const TestimonialSlider = ({ data, showHeading, pageType }) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1.15,
          slidesToScroll: 3,
          infinite: false,
          dots: false,
          centerPadding: "80px",
          centerMode: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1.15,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          centerPadding: "60px",
          centerMode: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.15,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          centerPadding: "50px",
          centerMode: true,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.15,
          slidesToScroll: 1,
          centerPadding: "5px",
          centerMode: true,
          arrows: false,
        },
      },
    ],
  };
  const mobile = useIsMobile();

let namesArr = pageType==="homepage" ? data.filter(i => i.category_type=== 'Home Page'): data.filter(i =>i?.categories && i?.categories[0]?.model_code === pageType);

  return (
    <div className="testimonial-section container-fluid mb-[100px] lg:mb-[150px]">
      <div className="container">
        {namesArr?.length>0 && (
         <h2 className="mt-14 text-primary-blue mb-[50px]"  style={{ fontSize: "30px",lineHeight:"37px",letterSpacing:".02em" }}>
         {Constants.testimonial.HEADING}
         </h2>
        )}
        <div className="testimonial-slides" id="testimonialSlides">
          <Slider
            arrows={data?.length > 3}
            className={` testimonial-sliderClass`}
            {...settings}
          >
            {data?.map((elem, index) => {
              if (elem?.category_type=== 'Model Page' && elem?.categories && elem.categories.length > 0 && elem?.categories[0]?.model_code === pageType) {
                return (
                  <div key={index} className="testimonial-wraps mt-[50px]" id="testimonial">
                    <div className="testimonial-box text-center relative mr-[30px] ml-[10px] bg-inverted rounded-[6px]  md:mb-[100px] mb-[50px]">
                      <div className="testimonial-inner-box ">
                        <Scrollbars  style={{ height: 200 }} autoHide  renderView={props => (
        <div {...props} style={{ ...props.style, marginTop:"52px" }} />
      )}>
                          {/* <div style={{marginTop:"52px",overflowY:"scroll"}}> */}
                          <div className="testimonial-desc px-[40px] pt-[65px] md:pt-[15px] b-2px ">
                            <div className="testimonial-desc-inner relative">
                              <div className="testimonial-beginning absolute left-[-22px] top-[-10px]">
                                <img
                                  src={`${configuration.IMG_STATIC_URL}assets/images/testimonial/quotationBegin.svg`}
                                  alt=""
                                  style={{height:"20px",width:"22px"}}
                                />
                              </div>
  
                              <p className="m-0 text-defaut-color text-[14px] font-suzukiRegular">
                                {elem?.testimonial_desc || elem?.description}
                              </p>
  
                              <div className="testimonial-end absolute right-[-20px] bottom-[0px]">
                                <img
                                  src={`${configuration.IMG_STATIC_URL}assets/images/testimonial/quotationEnd.svg`}
                                  alt=""
                                  style={{height:"20px",width:"22px"}}
                                />
                              </div>
                            </div>
                          </div>
                          {/* </div> */}
                        </Scrollbars>
                        <div className="testimonial-title pb-[24px] md:pb-[50px]">
                          <h2 className="m-0 mt-[12px] text-[14px] font-suzukiBold text-defaut-color ">
                            {elem.title || elem?.username}
                          </h2>                       
                          <p className="m-0 text-defaut-color text-[14px] font-suzukiRegular">
                            {elem.author_name || elem?.designation}
                          </p>
                        </div>
                        <div className="testimonial-profile absolute left-[50%] translate-x-[-50%] top-[-50px]">
                          <Image
                            className={"rounded-[100%]"}
                            src={
                              elem?.profile_img
                                ? `${configuration.IMG_URL}${elem.profile_img}`
                                : elem?.img_url
                                ? `${configuration.IMG_URL}${elem.img_url}`
                                : ""
                            }
                            height={mobile ? 100 : 100}
                            width={mobile ? 100 : 100}
                            alt={elem?.alt_tag_profile}
                          />
                        </div>
                      </div>
                    </div>
                    {/* </Scrollbars> */}
                  </div>
                );
              } else if (pageType==="homepage" && elem?.category_type=== 'Home Page'){
                return (
                  <div key={index} className="testimonial-wraps mt-[50px]" id="testimonial">
                    <div className="testimonial-box text-center relative mr-[30px] ml-[10px] bg-inverted rounded-[6px]  md:mb-[100px] mb-[50px]">
                      <div className="testimonial-inner-box ">
                        <Scrollbars  style={{ height: 200 }} autoHide  renderView={props => (
        <div {...props} style={{ ...props.style, marginTop:"52px" }} />
      )}>
                          {/* <div style={{marginTop:"52px",overflowY:"scroll"}}> */}
                          <div className="testimonial-desc px-[40px] pt-[65px] md:pt-[15px] b-2px ">
                            <div className="testimonial-desc-inner relative">
                              <div className="testimonial-beginning absolute left-[-22px] top-[-10px]">
                                <img
                                  src={`${configuration.IMG_STATIC_URL}assets/images/testimonial/quotationBegin.svg`}
                                  alt=""
                                  style={{height:"20px",width:"22px"}}
                                />
                              </div>
  
                              <p className="m-0 text-defaut-color text-[14px] font-suzukiRegular">
                                {elem?.testimonial_desc || elem?.description}
                              </p>
  
                              <div className="testimonial-end absolute right-[-20px] bottom-[0px]">
                                <img
                                  src={`${configuration.IMG_STATIC_URL}assets/images/testimonial/quotationEnd.svg`}
                                  alt=""
                                  style={{height:"20px",width:"22px"}}
                                />
                              </div>
                            </div>
                          </div>
                          {/* </div> */}
                        </Scrollbars>
                        <div className="testimonial-title pb-[24px] md:pb-[50px]">
                          <h2 className="m-0 mt-[12px] text-[14px] font-suzukiBold text-defaut-color ">
                            {elem.title || elem?.username}
                          </h2>                       
                          <p className="m-0 text-defaut-color text-[14px] font-suzukiRegular">
                            {elem.author_name || elem?.designation}
                          </p>
                        </div>
                        <div className="testimonial-profile absolute left-[50%] translate-x-[-50%] top-[-50px]">
                          <Image
                            className={"rounded-[100%]"}
                            src={
                              elem?.profile_img
                                ? `${configuration.IMG_URL}${elem.profile_img}`
                                : elem?.img_url
                                ? `${configuration.IMG_URL}${elem.img_url}`
                                : ""
                            }
                            height={mobile ? 100 : 100}
                            width={mobile ? 100 : 100}
                            alt={elem?.alt_tag_profile}
                          />
                        </div>
                      </div>
                    </div>
                    {/* </Scrollbars> */}
                  </div>
                );}
                else if(pageType==="all_components") return (
                  <div
                    key={index}
                    className="testimonial-wraps mt-[50px]"
                    id="testimonial"
                  >
                    <div className="testimonial-box text-center relative mr-[30px] ml-[10px] bg-inverted rounded-[6px]  md:mb-[100px] mb-[50px]">
                      <div className="testimonial-inner-box ">
                        <Scrollbars
                          style={{ height: 200 }}
                          autoHide
                          renderView={(props) => (
                            <div
                              {...props}
                              style={{ ...props.style, marginTop: "52px" }}
                            />
                          )}
                        >
                          {/* <div style={{marginTop:"52px",overflowY:"scroll"}}> */}
                          <div className="testimonial-desc px-[40px] pt-[65px] md:pt-[15px] b-2px ">
                            <div className="testimonial-desc-inner relative">
                              <div className="testimonial-beginning absolute left-[-22px] top-[-10px]">
                                <img
                                  src={`${configuration.IMG_STATIC_URL}assets/images/testimonial/quotationBegin.svg`}
                                  alt=""
                                  style={{ height: "20px", width: "22px" }}
                                />
                              </div>

                              <p className="m-0 text-defaut-color text-[14px] font-suzukiRegular">
                                {elem?.testimonial_desc || elem?.description}
                              </p>

                              <div className="testimonial-end absolute right-[-20px] bottom-[0px]">
                                <img
                                  src={`${configuration.IMG_STATIC_URL}assets/images/testimonial/quotationEnd.svg`}
                                  alt=""
                                  style={{ height: "20px", width: "22px" }}
                                />
                              </div>
                            </div>
                          </div>
                          {/* </div> */}
                        </Scrollbars>
                        <div className="testimonial-title pb-[24px] md:pb-[50px]">
                          <h2 className="m-0 mt-[12px] text-[14px] font-suzukiBold text-defaut-color ">
                            {elem.title || elem?.username}
                          </h2>
                          <p className="m-0 text-defaut-color text-[14px] font-suzukiRegular">
                            {elem.author_name || elem?.designation}
                          </p>
                        </div>
                        <div className="testimonial-profile absolute left-[50%] translate-x-[-50%] top-[-50px]">
                          <Image
                            className={"rounded-[100%]"}
                            src={
                              elem?.profile_img
                                ? `${configuration.IMG_URL}${elem.profile_img}`
                                : elem?.img_url
                                ? `${configuration.IMG_URL}${elem.img_url}`
                                : ""
                            }
                            height={mobile ? 100 : 100}
                            width={mobile ? 100 : 100}
                            alt={elem?.alt_tag_profile}
                          />
                        </div>
                      </div>
                    </div>
                    {/* </Scrollbars> */}
                  </div>
                ); 
                else return null             
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default TestimonialSlider;
